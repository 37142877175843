<template>
  <b-container fluid>
    <div class="row">
      <div class="col-3">
        <iq-card>
          <template v-slot:headerTitle>
            <h4 class="card-title">Detalles de Rol</h4>
          </template>
          <template v-slot:body>
            <ul class="list-group list-group-flush">
              <li class="list-group-item">
                <strong>Nombre:</strong
                ><span class="pull-right">{{ document.name }}</span>
              </li>
              <li class="list-group-item">
                <strong>Permisos Asignados:</strong
                ><span class="pull-right">{{ permissionsSelected.length }}</span>
              </li>
              <li class="list-group-item">
                <strong>Total Usuarios:</strong
                ><span class="pull-right">{{ document.users_total }}</span>
              </li>
            </ul>
          </template>
          <template v-slot:footer>
            <button class="btn btn-primary" @click="saveChanges">
              <i class="fa fa-floppy-o" /> Guardar Cambios
            </button>
          </template>
        </iq-card>
      </div>
      <div class="col-9">
        <div class="row">
          <div
            class="col-lg-6"
            v-for="(module, index) in document.system_modules"
            :key="index"
          >
            <iq-card>
              <template v-slot:headerTitle>
                <h4 class="card-title">{{ module.name }}</h4>
              </template>
              <template v-slot:body>
                <ul class="list-group list-group-flush">
                  <li
                    class="list-group-item"
                    v-for="(permission, index2) in module.permissions"
                    :key="index2"
                  >
                    <strong>{{ permission }}</strong>

                    <div class="custom-control custom-switch float-right">
                      <input
                        type="checkbox"
                        class="custom-control-input"
                        :id="`customSwitch${index}-${index2}`"
                        :checked="checkedIfExists(permission)"
                        @click="selectPermission(permission)"
                      />
                      <label class="custom-control-label" :for="`customSwitch${index}-${index2}`">
                      </label>
                    </div>
                  </li>
                </ul>
              </template>
            </iq-card>
          </div>
        </div>
      </div>
    </div>
  </b-container>
</template>

<script>
import { xray } from '../../../config/pluginInit'
import api from '../../../services/api'
import { Toast } from '../../../mixins/toast'

export default {
  mounted () {
    xray.index()
    this.getData()
  },
  data () {
    return {
      document: {
        permissions: [],
        system_permissions: [],
        users_total: 0
      },
      permissionsSelected: []
    }
  },
  methods: {
    async getData () {
      try {
        const { data } = await api.get(`/roles/${this.$route.params.id}`)
        this.document = data
        this.permissionsSelectedGenerate(this.document.permissions)
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Error'
        })
      }
    },
    loadDocument () {
      api.show(this.$route.params.id).then((r) => {
        this.document = r.data
        this.permissionsSelectedGenerate(this.document.permissions)
      })
    },
    permissionsSelectedGenerate (permissions) {
      this.permissionsSelected = permissions.map((item, index) => {
        return item.name
      })
    },
    checkedIfExists (permission) {
      if (this.permissionsSelected.indexOf(permission) !== -1) {
        return true
      }
      return false
    },
    selectPermission (permission) {
      let indexInSelected = this.permissionsSelected.indexOf(permission)
      let selectedThisPermission = indexInSelected !== -1

      if (selectedThisPermission) {
        this.permissionsSelected.splice(indexInSelected, 1)
        return true
      }

      this.permissionsSelected.push(permission)
      return true
    },
    async saveChanges () {
      try {
        await api.patch(`/roles/${this.$route.params.id}/permissions`, {
          permissions: this.permissionsSelected
        })
        this.getData()
        Toast.fire({
          icon: 'success',
          title: 'Actualizado Correctamente'
        })
      } catch (error) {
        Toast.fire({
          icon: 'error',
          title: 'Error'
        })
      }
    }
  }
}
</script>
